.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background: var(--dark-color);
  color: var(--light-color);
}

.custom-toggler.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-toggler.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 32, 110)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar .custom-toggler.navbar-toggler {
  border-color: none;
}

#animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#animated-icon span:nth-child(1) {
  top: 0px;
}

#animated-icon span:nth-child(2), #animated-icon span:nth-child(3) {
  top: 10px;
}

#animated-icon span:nth-child(4) {
  top: 20px;
}

#animated-icon.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

#animated-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#animated-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#animated-icon.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

#animated-icon span {
  background: var(--accent-color);
}

.fade-content {
  opacity: .3;
}

.disable-website {
  height: 100vh;
  position: fixed;
  z-index: 1020;
  width: 100%;
}

.form-control.custom-form-input:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 .2rem #ff206e69;
}

.custom-modal .modal-content {
  background: var(--dark-color);
  color: var(--light-color);
  border-color: var(--accent-color);
  font-family: 'Poppins', sans-serif;
}

.custom-button.btn-primary {
  background: var(--dark-color);
  color: var(--light-color);
  border-color: var(--accent-color);
}

.custom-button.btn-primary:hover {
  background: var(--accent-color);
  color: var(--light-color);
  border-color: var(--accent-color);
}

.custom-button.btn-primary:not(:disabled):not(.disabled).active, .custom-button.btn-primary:not(:disabled):not(.disabled):active, .custom-button.show>.btn-primary.dropdown-toggle {
  background: var(--accent-color);
  color: var(--light-color);
  border-color: var(--accent-color);
}

.custom-button.btn-primary.focus, .custom-button.btn-primary:focus {
  background: var(--accent-color);
  color: var(--light-color);
  border-color: var(--accent-color);
  box-shadow: 0 0 0 .2rem #ff206e69;
}

.custom-button.btn-primary:focus-visible {
  outline: none;
}